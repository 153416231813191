// extracted by mini-css-extract-plugin
export var button = "RegistrationForm-module--button--GcEyF";
export var ccsDiv = "RegistrationForm-module--ccsDiv--qBiry";
export var contactDetailsIcon = "RegistrationForm-module--contactDetailsIcon--QA7yv";
export var contactFormDiv = "RegistrationForm-module--contactFormDiv--fc66s";
export var contactFormInputsDiv = "RegistrationForm-module--contactFormInputsDiv--bY4Xr";
export var displayDiv = "RegistrationForm-module--displayDiv--baUnj";
export var footerContentDiv = "RegistrationForm-module--footerContentDiv--Uk9B0";
export var footerDiv = "RegistrationForm-module--footerDiv--cdDd0";
export var footerLDiv = "RegistrationForm-module--footerLDiv--08hj0";
export var footerLinksDiv = "RegistrationForm-module--footerLinksDiv--W5eLQ";
export var footerLogoDiv = "RegistrationForm-module--footerLogoDiv--dk3Va";
export var footerRDiv = "RegistrationForm-module--footerRDiv--JviWB";
export var formTitleDiv = "RegistrationForm-module--formTitleDiv--dSHz9";
export var fullNameDiv = "RegistrationForm-module--fullNameDiv--KZMNr";
export var globalCareSupply = "RegistrationForm-module--globalCareSupply--URLT9";
export var input = "RegistrationForm-module--input--omTfY";
export var inputFieldWIthLabelDiv = "RegistrationForm-module--inputFieldWIthLabelDiv--M8gll";
export var inputFieldWIthLabelDiv9 = "RegistrationForm-module--inputFieldWIthLabelDiv9--byxfj";
export var inputLabelDiv = "RegistrationForm-module--inputLabelDiv--NaZ7R";
export var inputLabelDiv9 = "RegistrationForm-module--inputLabelDiv9--Iyqjy";
export var inputRowDiv = "RegistrationForm-module--inputRowDiv--ESpSY";
export var inputRowDiv4 = "RegistrationForm-module--inputRowDiv4--GJL1p";
export var inputSelect = "RegistrationForm-module--inputSelect--zGORx";
export var logoA = "RegistrationForm-module--logoA--lGz8E";
export var passwordsShouldContainAMin = "RegistrationForm-module--passwordsShouldContainAMin--QaYj7";
export var privacyPolicy = "RegistrationForm-module--privacyPolicy--g8AYI";
export var registerContentDiv = "RegistrationForm-module--registerContentDiv--7egDq";
export var registerSectionDiv = "RegistrationForm-module--registerSectionDiv--0RpB-";
export var registrationPageDiv = "RegistrationForm-module--registrationPageDiv--8flcU";
export var udshSymbol1Icon = "RegistrationForm-module--udshSymbol1Icon--areZg";
export var visaIcon = "RegistrationForm-module--visaIcon--Aekrl";