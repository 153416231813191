import * as React from "react";
import * as styles from "./RegistrationForm.module.css";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { CheckIcon, PhoneIcon } from "@chakra-ui/icons";
import { gql, useMutation } from "@apollo/client";

import { getAllCountries } from "../hooks/getAllCountries";
import { navigate } from "gatsby";
import { useState } from "react";
import validator from 'validator'

const REGISTER_USER = gql`
  mutation MyMutation(
    $address1: String  
    $email: String  
    $firstName: String
    $password: String  
    $city: String 
    $company: String  
    $postcode: String 
    $state: String
    $country: CountriesEnum
    $phone: String
  ) {
    registerCustomer(
      input: {
        shipping: {
          address1: $address1, 
          city: $city, 
          company: $company, 
          postcode: $postcode, 
          state: $state,
          country: $country,
          phone: $phone
        }, 
        email: $email, 
        firstName: $firstName, 
        password: $password}
    ) {
    customer {
      databaseId
      firstName
    }
  }
}
`;

export default function Register() {

  var allCountries = getAllCountries()
  var regionNames = new Intl.DisplayNames (
    ['EN'], {type: "region"}
  );
  
  // Password toggle handler
  const [passwordShown, setPasswordShown] = useState(false);
  function togglePassword() {
    setPasswordShown(!passwordShown);
  }

  // Saving Form data 
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phone: "",
    address1: "",
    password: "",
    confirmPassword: "",
    state: "",
    postcode: "",
    country: "AF",
  })
  
  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input: any) => (e: any) => {
    // input value from the form
    const value = e.currentTarget.value;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  // Register User
  const [register, { data, loading, error }] = useMutation(REGISTER_USER);
  const wasSignUpSuccessful = Boolean(data?.registerCustomer?.customer?.firstName);

  const toast = useToast()
  // name
  const [nameError, setNameError] = useState("")
  //email 
  const [emailError, setEmailError] = useState("")
  // phone
  const [phoneError, setPhoneError] = useState("")
  // ZIP
  const [zipError, setZipError] = useState("")
  // password
  const [passwordError, setPasswordError] = useState("")
  // confirm password
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const doPasswordsMatch = formData.password == formData.confirmPassword

  const [errorCode, setErrorCode] = useState(false);


  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      
      const data:any = new FormData(event.currentTarget);
  
      const values = Object.fromEntries(data);

    // Name validation
      if ( ! validator.isLength(values.firstName, {min: 2, max: 100})) {
        setNameError("Full name is too short.");
        setErrorCode(true);
      } else {
        setNameError("")
          setErrorCode(false);
      }

    //Email validation 
    if (!validator.isEmail(values.email)) {
      setEmailError("Enter a valid email address.")
      setErrorCode(true);
    } else {
      setEmailError("");
      setErrorCode(false)
    }
    
    // Phone validation
    if (!validator.isLength(values.phone, { min: 6, max: 15 })) {
      setPhoneError("Phone number is too short.")
      setErrorCode(true);
    } else if (!validator.isMobilePhone(values.phone)) {
      setPhoneError("Phone number must be a number.")
    } else {
      setPhoneError("")
        setErrorCode(false);
    }
    
    // ZIP validation
    if (! validator.isLength(values.postcode, {min: 4, max: 12})) {
      setZipError("Zip must be at least 4 characters long.")
      setErrorCode(true);
    } else {
      setZipError("")
        setErrorCode(false);
    }
      
    // Password validation
    if (!validator.isStrongPassword(values.password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false, pointsPerUnique: 1, pointsPerRepeat: 0.5, pointsForContainingLower: 10, pointsForContainingUpper: 10, pointsForContainingNumber: 10, pointsForContainingSymbol: 10 })) {
      setPasswordError("Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters, numbers, andspecial characters.")
      setErrorCode(true);
    } else {
      setPasswordError("")
        setErrorCode(false);
    }

    // Confirm password validation
    if (!doPasswordsMatch) {
      setConfirmPasswordError("Passwords don't match");
      setErrorCode(true)
    } else {
      setConfirmPasswordError("")
      setErrorCode(false)
    }

    if (errorCode == false) {
      register({
        variables: values,
      }).catch(error => {

      if (error == "Error: Internal server error") {
          navigate('/ty-for-registration');
      } else if (error == 'Error: An account is already registered with your email address. <a href="#" class="showlogin">Please log in.</a>') {
        toast({
          status: "error",
          description: 'An account is already registered with your email address'
        })
      } 
      });
      }
  }

  if (wasSignUpSuccessful) {
    navigate('/ty-for-registration');
  }
    
  return (
    <div className={styles.registrationPageDiv}>
      <div className={styles.registerSectionDiv}>
        <div className={styles.registerContentDiv}>
          <img className={styles.patternsIcon} alt="" src="/patterns1.svg" />
          <div className={styles.contactFormDiv}>
        <form onSubmit={handleSubmit} method="post">

            <div className={styles.contactFormInputsDiv}>
              <div className={styles.formTitleDiv}>
                <div className={styles.displayDiv}>Register</div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Full Name</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    id="firstName" type="text" name="firstName" placeholder="John Doe"
                    onChange={handleInputData("firstName")}
                    defaultValue={formData.firstName}
                    required
                    />
                    {nameError ? <Text color="red">{ nameError }</Text> : <></>}

                </div>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Email</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    placeholder="johndoe@email.com"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleInputData("email")}
                    defaultValue={formData.email}
                    required
                  />
                  {emailError ? <Text color="red">{ emailError }</Text> : <></>}
                </div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Phone Number</div>
                  </div>
                  <InputGroup className={styles.input}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<PhoneIcon color="gray.300" mt="6px"/>}
                    />
                    <Input
                      className={styles.input}
                      variant="outline"
                      textColor="#050e15"
                      borderColor="#ebf3fb"
                      focusBorderColor="#3182ce"
                      placeholder="00 38 69 123 456 789"
                      id="phone" name="phone" onChange={handleInputData("phone")}
                      defaultValue={formData.phone}
                      required                         
                    />
                    </InputGroup>
                     {phoneError ? <Text color="red">{phoneError}</Text> : <></>}
                </div>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Address</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#f3fbf0"
                    focusBorderColor="#3182ce"
                    placeholder="Address Line"
                    id="address1" name="address1" onChange={handleInputData("address1")}
                    defaultValue={formData.address1}
                    required
                  />
                </div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>ZIP Code</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    placeholder="1234"
                    id="postcode" name="postcode" onChange={handleInputData("postcode")}
                    defaultValue={formData.postcode}
                    required
                    />
                    {zipError ? <Text color="red">{zipError}</Text> : <></>}
                </div>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>State/Region</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    placeholder="State"
                    id="state" name="state" onChange={handleInputData("state")}
                    defaultValue={formData.state}
                    required
                  />
                </div>
              </div>
              <div className={styles.inputRowDiv}>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Country</div>
                </div>
                <Select className={styles.inputSelect} h="12" variant="outline" borderColor="#ebf3fb"
                    focusBorderColor="#3182ce" id="country" name="country" onChange={handleInputData("country")}>
                    {allCountries.map((item: any) => {return <option value={item.countryCode}>{ regionNames.of(item.countryCode) }</option> })}
                </Select>
                  
                </div>
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>VAT (optional)</div>
                  </div>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    placeholder="1234"
                  />
                </div>
              </div>
              <div className={styles.inputRowDiv4} >
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Password</div>
                    </div>
                   <InputGroup>
                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    id="password" name="password" onChange={handleInputData("password")}
                    defaultValue={formData.password}
                    type={passwordShown ? "text" : "password"}   
                    required    
                    />
                    <InputRightElement>
                      <Button onClick={togglePassword} background="transparent" color="gray.700" _hover={{ bg: 'transparent', border: "none" }} _focus={{ border: "none"}} >{passwordShown ? <Icon as={AiFillEyeInvisible} ></Icon> : <Icon as={AiFillEye}></Icon>}</Button>
                    </InputRightElement>
                    </InputGroup>
                    <div className={styles.inputRowDiv4} >
                <div className={styles.inputFieldWIthLabelDiv}>
                  <div className={styles.inputLabelDiv}>
                    <div className={styles.fullNameDiv}>Confirm Password</div>
                    </div>
                   <InputGroup>

                  <Input
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#3182ce"
                    id="confirmPassword" name="confirmPassword" 
                    onChange={handleInputData("confirmPassword")}
                    defaultValue={formData.confirmPassword}
                    type={passwordShown ? "text" : "password"}   
                    required    
                    />
                    <InputRightElement>
                      <Button onClick={togglePassword} background="transparent" color="gray.700" _hover={{ bg: 'transparent', border: "none" }} _focus={{ border: "none"}} >{passwordShown ? <Icon as={AiFillEyeInvisible} ></Icon> : <Icon as={AiFillEye}></Icon>}</Button>
                    </InputRightElement>
                    </InputGroup>
                    {confirmPasswordError ? <Text color="red">{confirmPasswordError}</Text> : <></>}

                  </div>
                  
              </div>
                    {passwordError ? <Text color="red" fontSize="12">Passwords should contain a minimum of 8 characters, using
                      a mix of uppercase and lowercase letters, numbers, and
                      special characters.</Text> :<Text fontSize="12">Passwords should contain a minimum of 8 characters, using
                      a mix of uppercase and lowercase letters, numbers, and
                      special characters.</Text>}
                </div>
              </div>

              <div className={styles.inputFieldWIthLabelDiv9}>
                <div className={styles.inputLabelDiv}>
                  <div className={styles.fullNameDiv}>Message (optional)</div>
                </div>
                <Textarea
                  className={styles.inputSelect}
                  variant="outline"
                  placeholder="Text Area"
                  textColor="#050e15"
                  borderColor="#ebf3fb"
                  focusBorderColor="#3182ce"
                />
              </div>
              <Button
                className={styles.button}
                variant="solid"
                colorScheme="gcsblue"
                type="submit"
              >
                Register
              </Button>
                </div>
            </form>

          </div>
        </div>
        <img
          className={styles.contactDetailsIcon}
          alt=""
          src="/contactdetails1@2x.png"
        />
      </div>
      
    </div>
  );
};

